import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataShareService, SurfAuthorizationService} from '@surf/surf-components-core';
import { PermissionKeys } from '../security/permission-constants';
import dpAddBarOperations from '../security/dp-add-bar-operations.json';

@Component({
  selector: 'surf-dp-add-bar',
  templateUrl: 'dp-add-bar.component.html'
})
export class DPAddBarComponent implements OnInit {

  @Input() isDPSearch = false;
  @Input() isDisabled = false;
  @Input() isIntro: boolean = false;
  @Input() isRefine: boolean = false;
  @Input() preItemIndex: number;
  @Input() postItemIndex: number;
  @Input() totalItemCount: number;
  @Input() disableDPSwitch = false;
  @Input() msg = '';
  @Input() activatedItems = [];

  @Output() addItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() showBarPanelToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  permissionKeys;
  showBar : boolean = false;
  tourEnableInDp: boolean = false;
  BETWEEN = 'between';
  BEFORE = 'before';
  AFTER = 'after';

  constructor(protected commonService: DataShareService,
              private surfAuthorizationService: SurfAuthorizationService) {
  }

  ngOnInit(): void {
    this.activatedItems = ['FLT']
    this.setAuthenticationParameters();
    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg != undefined && msg.split('~') != undefined && (msg.split('~')[0] === 'DP_Switch')) {

        if (msg.split('~')[2] == 'pre' && this.preItemIndex == -1) {
          this.showBar = true;
        }
        if (msg.split('~')[2] == 'post' && this.preItemIndex == 0) {
          this.showBar = true;
        }
      }

      if (msg != undefined && msg.split('~') != undefined && (msg.split('~')[0] === 'DP_bar_opened')) {
        /*If not me, close immediately*/
        if (this.preItemIndex + '' != msg.split('~')[1] &&
          this.postItemIndex + '' != msg.split('~')[2]) {
          this.closeBar();
        }
      }
    });
  }

  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(dpAddBarOperations);
    const tourEnablePermissionInDp = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.SURF_DP_ADD_BAR_TOUR_ICON_ENABLE);

    if (tourEnablePermissionInDp && tourEnablePermissionInDp.allowed) {
      this.tourEnableInDp = true;
    } else {
      this.tourEnableInDp = false;
    }
  }

  addCriteriaItem(productCode: string, event) {
    // this.commonService.changeMessage(this.msg);
    if (this.isIntro) {                   /*only in the initial add*/
      this.addItem.emit(productCode);
    } else {
      if (!(productCode === 'FLT' && this.msg.split('~')[1] === 'FLT')) {
        const newMsg = 'DP_Switch~' + productCode + '~' + this.msg.split('~')[2];
        if (this.msg.split('~')[2] === 'pre') {
          this.commonService.changeMessage(newMsg + '~' + this.msg);
        } else if (this.msg.split('~')[2] === 'post') {
          this.commonService.changeMessage(this.msg + '~' + newMsg);
        } else {
          this.commonService.changeMessage(this.msg + '~' + newMsg + '~' + this.msg.split('~')[2]);
        }
      }
      this.addItem.emit({preItemIndex: this.preItemIndex, postItemIndex: this.postItemIndex, newItemType: productCode});
      this.showBar = false;

      if (event && productCode == 'FLT') {
        event.stopPropagation();
      }
    }
  }

  isInCenter() {
    return this.preItemIndex >= 0 && this.postItemIndex <= (this.totalItemCount - 1);
  }

  generateAddBarDetailedText() {
    if (this.isInCenter()) {
      return this.BETWEEN;
    } else if (this.preItemIndex < 0) {
      return this.BEFORE;
    } else if (this.postItemIndex === this.totalItemCount) {
      return this.AFTER;
    }
    return '';
  }

  showBarPanel() {
    if(this.isDisabled){
      return;
    }
    if (!this.isRefine) {      /*TODO: Implement for refine*/
      this.showBar = true;
      this.commonService.changeMessage('DP_bar_opened~' + this.preItemIndex + '~' + this.postItemIndex);
      this.showBarPanelToggle.emit(true);
    }
  }

  closeBar(){
    this.showBar = false;
    this.commonService.changeMessage("");
    this.showBarPanelToggle.emit(false);
  }

  /**
   * active the button when flight refine
   */
  isButtonDisabled(itemCode): boolean {
    try{
      return this.disableDPSwitch && !this.activatedItems.includes(itemCode);
    }
    catch (e){
      return false
    }
  }
}
