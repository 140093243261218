<div class="c-dp-search__add-block c-dp-search__add-block--bar"  *ngIf="showBar || isIntro">

  <div class="c-dp-search__action" *ngIf="showBar && !isIntro"
       (click)="closeBar()">
    <div class="c-dp-search__hide-icon">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>
  </div>

  <div class="c-dp-search__add-bar auto-product-list" *ngIf="showBar || isIntro">

    <div class="c-dp-search__add-bar-item" (click)="addCriteriaItem('HTL', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">
      <span class="a-tool-tip a-tool-tip--top c-dp-search__tool-tip" data-tooltip="Accommodation">
          <svg class="a-icon">
              <use xlink:href="#products--hotel"></use>
          </svg>
      </span>
    </div>

    <div class="c-dp-search__add-bar-item auto-add-another-flight" (click)="addCriteriaItem('FLT', $event)" [ngClass]="{'u-disabled': isButtonDisabled('FLT')}">
      <span class="a-tool-tip a-tool-tip--top c-dp-search__tool-tip" data-tooltip="Flights">
          <svg class="a-icon">
            <use xlink:href="#products--flight"></use>
          </svg>
      </span>
    </div>

    <div class="c-dp-search__add-bar-item" (click)="addCriteriaItem('CAR', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">
      <span class="a-tool-tip a-tool-tip--top c-dp-search__tool-tip" data-tooltip="Cars">
          <svg class="a-icon">
            <use xlink:href="#products--car"></use>
          </svg>
      </span>
    </div>

    <div class="c-dp-search__add-bar-item" (click)="addCriteriaItem('TRS', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">
      <span class="a-tool-tip a-tool-tip--top c-dp-search__tool-tip" data-tooltip="Transfer">
          <svg class="a-icon">
            <use xlink:href="#products--bus"></use>
          </svg>
      </span>
    </div>

<!--    This commented because kuoni not need this-->

<!--    <div class="c-dp-search__add-bar-item" *ngIf="tourEnableInDp" (click)="addCriteriaItem('TOU', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">-->
<!--      <span class="a-tool-tip a-tool-tip&#45;&#45;top c-dp-search__tool-tip" data-tooltip="Tours">-->
<!--          <svg class="a-icon">-->
<!--            <use xlink:href="#products&#45;&#45;tour"></use>-->
<!--          </svg>-->
<!--      </span>-->
<!--    </div>-->

    <div class="c-dp-search__add-bar-item" (click)="addCriteriaItem('GEN', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">
      <span class="a-tool-tip a-tool-tip--top c-dp-search__tool-tip" data-tooltip="Activities">
          <svg class="a-icon">
            <use xlink:href="#products--tickets"></use>
          </svg>
      </span>
    </div>

<!--    This commented because kuoni not need this-->

<!--    <div class="c-dp-search__add-bar-item" *ngIf="isDPSearch || totalItemCount > 0" (click)="addCriteriaItem('OWA', $event)" [ngClass]="{'u-disabled': disableDPSwitch}">-->
<!--      <span class="a-tool-tip a-tool-tip&#45;&#45;top c-dp-search__tool-tip" data-tooltip="Own arrangement">-->
<!--          <svg class="a-icon">-->
<!--            <use xlink:href="#products&#45;&#45;ownarrangment"></use>-->
<!--          </svg>-->
<!--      </span>-->
<!--    </div>-->

  </div>

</div>

<div class="c-dp-search__add-block" *ngIf="(!isIntro && !showBar)"
     [ngClass]="{'' : isInCenter(), 'u-disabled': isRefine || isDisabled}">   <!--TODO: Disabled in refine for now, implement this-->
  <div class="c-dp-search__action" (click)="showBarPanel()">
    <span class="c-dp-search__add-dot"></span>
    <div class="c-dp-search__add-icon auto-add-component">
      <svg class="a-icon">
        <use xlink:href="#plus"></use>
      </svg>
    </div>
  </div>
  <span class="c-dp-search__add-text auto-add-another-component" (click)="showBarPanel()">Add another item
   <span *ngIf="isDPSearch" class="ts-bold">{{generateAddBarDetailedText()}}</span>
  </span>
</div>
